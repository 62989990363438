import React from "react"
import { OutboundLink } from "gatsby-plugin-gtag"
import "./Layout.css"

const GalleryImage = ({ imgurl, buyurl }) => (
  <OutboundLink
    href={buyurl}
    target="_blank"
    rel="noopener noreferrer"
    style={styles.container}
  >
    <img src={imgurl} alt="buy" height={90} />
  </OutboundLink>
)

const styles = {
  container: {
    marginRight: "0.5rem",
  },
}

export default GalleryImage
