import React from "react"
import rehypeReact from "rehype-react"
import { OutboundLink } from "gatsby-plugin-gtag"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import TagsContainer from "../components/TagsContainer"
import Gallery from "../components/Gallery"
import GalleryImage from "../components/GalleryImage"
import Title from "../components/Title"
import PostList from "../components/PostList"
import Headshot from "../components/Headshot"
import { getRecentPayload } from "../lib/RecentHelper"
import Colors from "../constants/Colors"
import Strings from "../constants/Strings"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    gallery: Gallery,
    "gallery-image": GalleryImage,
    "outbound-link": OutboundLink,
  },
}).Compiler

const AVATAR_DIM = 40

const BlogPost = props => {
  const { data } = props
  const { post, allPosts } = data
  const { title, subtitle, tags, date } = post.frontmatter
  const recentPosts = getRecentPayload(allPosts, post.fields.slug)

  return (
    <Layout override="marginStandard">
      <SEO title={title} description={post.excerpt} />
      <div className="marginFocus">
        <Title>{title}</Title>
        <div style={styles.authorContainer}>
          <Headshot dimension={AVATAR_DIM} screenStyles={styles.avatarStyle} />
          <div>
            <p
              style={{
                ...styles.spaced,
                marginBottom: "0px",
              }}
            >
              {subtitle}
            </p>
            <p
              style={{
                ...styles.spaced,
                marginBottom: "0px",
              }}
            >
              {date}
            </p>
          </div>
        </div>
        <div style={styles.bodyText}>{renderAst(post.htmlAst)}</div>
        <TagsContainer tags={tags} />
        <PostList posts={recentPosts} title={Strings.Recent.Title} />
      </div>
    </Layout>
  )
}

const styles = {
  avatarStyle: {
    marginRight: "1.0rem",
    marginBottom: "0px",
  },
  authorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    marginBottom: "1.0rem",
  },
  spaced: {
    color: Colors.gray2,
    fontSize: "0.8rem",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "1.0rem",
  },
  bodyText: {
    marginBottom: "3.0rem",
  },
}

export default BlogPost

export const query = graphql`
  query PostQuery($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      excerpt
      frontmatter {
        title
        subtitle
        date(formatString: "MM.DD.YYYY")
        tags
      }
      fields {
        slug
      }
    }
    allPosts: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { type: { eq: "blog" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MM.DD.YYYY")
            title
            tags
          }
          rawMarkdownBody
        }
      }
    }
  }
`
