import React from "react"
import { replaceDashWithSpace } from "../lib/StringHelper"
import Colors from "../constants/Colors"
import "./Link.css"

const TagsContainer = ({ tags, limit, screenStyles }) => (
  <div style={{ ...styles.tagContainer, ...screenStyles }}>
    {tags.slice(0, limit).map((tag, i) => {
      if (tag.fieldValue) {
        return (
          <Tag tag={tag.fieldValue} count={tag.totalCount} key={i} index={i} />
        )
      }
      return <Tag tag={tag} key={i} index={i} />
    })}
  </div>
)

const Tag = ({ tag, count, index }) => {
  const tagNormalized = replaceDashWithSpace(tag)
  let tagTitle = tagNormalized
  if (count) {
    tagTitle = `${tagNormalized} (${count})`
  }
  return (
    <a href={`/s/${tag}`} className="link" key={index} style={styles.tag}>
      {tagTitle.toUpperCase()}
    </a>
  )
}

const styles = {
  tagContainer: {
    marginBottom: "20px",
    maxWidth: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
  },
  tag: {
    background: Colors.gray3,
    marginRight: "10px",
    marginBottom: "10px",
    fontSize: "0.6rem",
    fontWeight: 500,
    lineHeight: "1.2rem",
    color: Colors.gray1,
    paddingLeft: 6,
    paddingRight: 6,
  },
}

export default TagsContainer
