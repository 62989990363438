import React from "react"
import "./Layout.css"

const Gallery = props => <div style={styles.container}>{props.children}</div>

const styles = {
  container: {
    display: "flex",
    flex: 1,
    marginTop: "0.5rem",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  text: {
    color: "white",
    fontSize: "0.75rem",
  },
}

export default Gallery
