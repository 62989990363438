import React from "react"

const Title = ({ children }) => (
  <h1
    style={{
      ...styles.marginTop,
      ...styles.centerText,
      ...styles.marginVert,
    }}
  >
    {children}
  </h1>
)

const styles = {
  marginTop: {
    marginTop: "1.6rem",
  },
  marginVert: {
    marginBottom: "1.0rem",
  },
  centerText: {
    textAlign: "left",
  },
}

export default Title
